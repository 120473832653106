<template>
    <div>
        <van-nav-bar
          :title="title"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="m-breadcrumb">
          <el-breadcrumb separator="/" replace>
            <el-breadcrumb-item :to="{ path: '/' }" >{{ $t('user.location') }}：{{ $t('common.webName') }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/user' }" >{{ $t('user.user') }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
    </div>
</template>
<script>
import { NavBar } from 'vant';

export default {
    props: {
        title: String
    },
    components: {
        [NavBar.name]: NavBar, 
    },
    methods: {
        onClickLeft(){
            this.$router.back()
        }
    }
}
</script>
<style scoped lang="scss">
    ::v-deep .van-nav-bar{
        .van-icon{
            color: #333;
        }
        .van-nav-bar__title{
            font-weight: bold;
        }
    }
    .m-breadcrumb{
        display: flex;
        line-height: 1.5;
        padding: 14px 20px;
        align-items: center;
        .el-breadcrumb{
            padding: 0;
            .el-breadcrumb__item[aria-current]{
            ::v-deep .el-breadcrumb__inner{
                color: var(--main-bg-color);
            }
            }
        }
    }
</style>