<template>
    <div class="m-pagination" v-if="totalPages > 1">
        <span class="m-page-prev " :class="{ 'm-disabled': currentPage === 1 }" @click="goToPreviousPage">{{$t('vanPagination.prev')}}</span>
        <span class="m-page-num">{{ currentPage }}/{{ totalPages }}</span>
        <span  class="m-page-next" :class="{ 'm-disabled': currentPage === totalPages }" @click="goToNextPage" :disabled="currentPage === totalPages">{{$t('vanPagination.next')}}</span>
    </div>
</template>
<script>
export default {
    props: {
        page: {
            type: [String, Number],
            default: 1
        },
        size: {
            type: [String, Number],
            default: 10
        },
        total: {
            type: [Number],
            default: 1
        }
    },
    data(){
        return {
            currentPage: 1,
            totalPages: 20,
        }
    },
    mounted(){
        this.currentPage = this.page;
        this.totalPages = Math.ceil(this.total / this.size)
    },
    watch: {
        currentPage(newVal){
            if(newVal){
                this.$emit('current-change', newVal)
            }
        },
        total(newVal){
            this.currentPage = 1
            this.totalPages = Math.ceil(newVal / this.size)
        }
    },
    methods: {
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
    }
}
</script>
<style scoped lang="scss">
.m-pagination {
    background-color: #f8f8f8;
    // margin-top: 10px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        background-color: #fff;
        width: 70px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 4px;
        margin: 0 10px;
        padding: 0 10px;
        &.m-disabled{
            background-color: #eee;
            color: #bbb;
        }
    }
}
</style>