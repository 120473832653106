<!--
 * @Author: gyy
 * @Date: 2023-12-08 09:38:13
 * @LastEditors: 自由命名
 * @LastEditTime: 2023-12-11 18:00:39
 * @FilePath: \zixun-vue\src\views\OrderList.vue
 * @filePurpose:
-->
<template>
  <div class="myOrder">
    <el-main class="pc" v-if="isMobile === false">
      <template v-if="tableData.length">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column prop="orderCode" label="订单号" width="240"> </el-table-column>
          <el-table-column prop="memberName" label="会员名称">
          </el-table-column>
          <el-table-column prop="payNum" label="咨询次数">
          </el-table-column>
          <el-table-column prop="price" label="价格" width="120"> </el-table-column>
          <el-table-column label="支付方式">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.payType == 1
                  ? "微信支付"
                  : scope.row.payType == 2
                  ? "支付宝支付"
                  : "paypal支付"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="支付时间"> </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNum"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="page.total"
          :pager-count="8"
          hide-on-single-page
        >
        </el-pagination>
      </template>
      <template v-else>
        <div class="noData">
          <img src="@/assets/none.png" alt="" />
          {{$t('common.noData')}}
        </div>
      </template>
    </el-main>
    <div v-else class="m-orders-page">
      <Header :title="$t('user.myOrders')"></Header>
      <div class="m-orders-list">
        <div class="m-order-item" v-for="item in tableData" :key="item.id">
          <div class="m-order-top">
            <h3>{{ item.title }}</h3>
            <span>{{ formatPayType(item.payType) }} ￥{{ item.price }}</span>
          </div>
          <div class="m-order-bott">{{ item.createTime }}</div>
        </div>
      </div>
      <Pagination :size="page.pageSize" @current-change="handleCurrentChange" :total="page.total"></Pagination>
      <Bottom></Bottom>
    </div>
  </div>
</template>
<script>
import { getOrder } from "@/api/user";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";
import Pagination from "@/components/mobile/Pagination.vue";

export default {
  name: "orderList",
  components: {
    Bottom,
    Header,
    Pagination
  },
  data() {
    return {
      isMobile: false,
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.getList();
  },
  methods: {
    formatPayType(val){
      const map = {
        1: '微信支付',
        2: '支付宝支付',
        3: 'PayPal支付'
      }
      return val ? map[val] : ''
    },
    getList() {
      let data = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      getOrder(data).then((res) => {
        console.log(res);
        this.tableData = res.rows;
        this.page.total = res.total;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.pageNum = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.pc {
  padding: 20px !important;
  background: #fff;
  min-height: 65vh;
}
.el-pagination {
  text-align: center;
  margin-top: 30px;
}
.m-orders-page{
  background-color: #f8f8f8;
  .m-orders-list{
    margin: 0 20px;
    .m-order-item{
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      padding: 10px;
      .m-order-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        h3{
          font-size: 14px;
          font-weight: normal;
        }
        span{
          font-size: 12px;
        }
      }
      .m-order-bott{
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
<style>
.myOrder .el-table th.el-table__cell {
  background: #f9f9f9;
}
</style>

